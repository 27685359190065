<template>
    <v-card elevation="0" outlined>
        <v-card-title>
            <v-row dense>
                <v-col cols="12" sm="12" md="8">
                    <CustomTextField
                        clearable
                        id="search"
                        inputIcon="mdi-magnify"
                        inputPlaceholder="Search..."
                        :inputValue="search"
                        @input="updateLocalForm($event, 'search')"
                        @click:clear="search = ''"
                    />
                </v-col>

                <!-- <v-col>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="mr-1 avsb-button"
                                depressed
                                v-on="on"
                                v-bind="attrs"
                                width="100px"
                            >
                                <span>Actions</span>
                                <v-icon right>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                dense
                                link
                                :disabled="isBulkSelected(selectedItems)"
                            >
                                <v-list-item-icon
                                    class="avsb-table-action-icons"
                                >
                                    <v-icon dense left> mdi-delete </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <div
                                                v-bind="attrs"
                                                v-on="on"
                                                class="mb-0"
                                            >
                                                <span>Remove </span>
                                            </div>
                                        </template>
                                        <span>Remove Items</span>
                                    </v-tooltip>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col> -->

                <v-col cols="12" sm="12" md="4">
                    <div :class="{ 'float-right': !$vuetify.breakpoint.xs }">
                        <CustomButton
                            :block="$vuetify.breakpoint.xs"
                            btnColour="primary"
                            btnLabel="Add Customer"
                            tooltipMsg="Add Customer"
                            @click="showCreateDialog"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
            <v-data-table
                :footer-props="footers"
                :headers="headers"
                :items="customers"
                :loading="retrieveOps.isLoading"
                loading-text="Loading..."
                no-data-text="No data so far."
                no-results-text="No matches found."
                :options.sync="options"
                :search="search"
                :server-items-length="retrieveOps.serverItemsLength"
                show-select
                v-model="selectedItems"
                @toggle-select-all="toggleBulkActions"
                @update:items-per-page="retrieveItems(options)"
                @update:page="retrieveItems(options)"
                @update:sort-by="retrieveItems(options)"
                @update:sort-desc="retrieveItems(options)"
            >
                <!-- Helper methods -->
                <template v-slot:item.datetime_created="{ item }">
                    <span>{{ toLocalDateTime(item.datetime_created) }}</span>
                </template>

                <template v-slot:item.datetime_disabled="{ item }">
                    <span
                        :class="
                            isDisabled(item.datetime_disabled)
                                ? 'red--text'
                                : 'green--text'
                        "
                    >
                        <strong>
                            {{
                                isDisabled(item.datetime_disabled)
                                    ? "Disabled"
                                    : "Enabled"
                            }}
                        </strong>
                    </span>
                </template>

                <!-- Item actions -->
                <template v-slot:item.actions="{ item }">
                    <v-btn icon small tile @click="viewItem(item)">
                        <v-icon>mdi-file-find</v-icon>
                    </v-btn>

                    <!-- More actions -->
                    <v-menu close-on-click offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon small tile v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <!-- Disable item option-->
                            <v-list-item
                                @click="showDisableDialog(item)"
                                dense
                                v-if="!isDisabled(item.datetime_disabled)"
                            >
                                <v-list-item-icon
                                    class="avsb-table-action-icons"
                                >
                                    <v-icon dense> mdi-lock </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <div
                                                v-bind="attrs"
                                                v-on="on"
                                                class="mb-0"
                                            >
                                                <span>Disable</span>
                                            </div>
                                        </template>
                                        <span>Disable Customer</span>
                                    </v-tooltip>
                                </v-list-item-content>
                            </v-list-item>

                            <!-- Enable item option-->
                            <v-list-item
                                @click="showEnableDialog(item)"
                                dense
                                v-if="isDisabled(item.datetime_disabled)"
                            >
                                <v-list-item-icon
                                    class="avsb-table-action-icons"
                                >
                                    <v-icon dense> mdi-lock-open </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <div
                                                v-bind="attrs"
                                                v-on="on"
                                                class="mb-0"
                                            >
                                                <span>Enable</span>
                                            </div>
                                        </template>
                                        <span>Enable Customer</span>
                                    </v-tooltip>
                                </v-list-item-content>
                            </v-list-item>

                            <!-- Remove item dialog -->
                            <v-list-item @click="showRemoveDialog(item)" dense>
                                <v-list-item-icon
                                    class="avsb-table-action-icons"
                                >
                                    <v-icon dense> mdi-delete </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <div
                                                v-bind="attrs"
                                                v-on="on"
                                                class="mb-0"
                                            >
                                                <span>Remove </span>
                                            </div>
                                        </template>
                                        <span>Remove Customer</span>
                                    </v-tooltip>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card-text>

        <!-- Dialogs -->
        <DialogCustomerView />
        <!-- Action dialogs -->
        <!-- Disable dialog -->
        <CustomActionConfirmationDialog
            dlgConfirmColour="error"
            dlgConfirmMessage="Disable"
            dlgHeader="Disable Customer"
            :dlgLoading="disableOps.isLoading"
            :dlgShow="disableOps.dialog"
            @closeDialog="hideDisableDialog"
            @actionCancel="hideDisableDialog"
            @actionConfirm="disableItem(disablePayload)"
        >
            <template v-slot:question>
                <span
                    >Are you sure you want to disable the following item:
                    <strong>{{ disablePayload.name }}</strong>
                </span>
            </template>
        </CustomActionConfirmationDialog>

        <!-- Enable dialog -->
        <CustomActionConfirmationDialog
            dlgConfirmMessage="Enable"
            dlgHeader="Enable Customer"
            :dlgLoading="enableOps.isLoading"
            :dlgShow="enableOps.dialog"
            @closeDialog="hideEnableDialog"
            @actionCancel="hideEnableDialog"
            @actionConfirm="enableItem(enablePayload)"
        >
            <template v-slot:question>
                <span
                    >Are you sure you want to enable the following item:
                    <strong>{{ enablePayload.name }}</strong>
                </span>
            </template>
        </CustomActionConfirmationDialog>

        <!-- Remove dialog -->
        <CustomActionConfirmationDialog
            dlgConfirmColour="error"
            dlgConfirmMessage="Remove"
            dlgHeader="Remove Customer"
            :dlgLoading="removeOps.isLoading"
            :dlgShow="removeOps.dialog"
            @closeDialog="hideRemoveDialog"
            @actionCancel="hideRemoveDialog"
            @actionConfirm="removeItem(removePayload)"
        >
            <template v-slot:question>
                <span
                    >Are you sure you want to remove the following item:
                    <strong>{{ removePayload.name }}</strong></span
                >
            </template>
        </CustomActionConfirmationDialog>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import {
    toLocalDateTime,
    isDisabled,
    isBulkSelected,
} from "@/services/helpers";

export default {
    components: {
        CustomActionConfirmationDialog: () =>
            import("@/components/dialogs/customActionConfirmationDialog.vue"),
        DialogCustomerView: () =>
            import("@/components/admin/dialogs/dialogCustomerView"),
    },
    computed: {
        ...mapState("customersAdminMgmt", [
            "customers",
            "retrieveOps",
            "disableOps",
            "enableOps",
            "removeOps",
            "disablePayload",
            "enablePayload",
            "removePayload",
            "bulkPayload",
        ]),
    },
    data: () => ({
        footers: {
            "items-per-page-options": [20, 50, 100],
        },
        headers: [
            { sortable: true, text: "Customer", value: "name" },
            { sortable: true, text: "Abbreviation", value: "abbreviation" },
            {
                align: "end",
                sortable: true,
                text: "Date Added",
                value: "datetime_created",
                width: 150,
            },
            {
                align: "end",
                sortable: true,
                text: "Status",
                value: "datetime_disabled",
                width: 150,
            },
            {
                align: "center",
                sortable: false,
                text: "Actions",
                value: "actions",
                width: 150,
            },
        ],
        search: "",
        selectedItems: [],
        options: {},
    }),
    methods: {
        isBulkSelected,
        isDisabled,
        toLocalDateTime,
        searchItems(options, term) {
            let params = {
                page: options.page,
                page_size: options.itemsPerPage,
                order_by: options.sortBy[0],
                order_desc: options.sortDesc[0],
                search: term,
            };
            this.$store.dispatch("customersAdminMgmt/searchItems", params);
        },
        retrieveItems(options, term) {
            if (!term) {
                let params = {
                    page: options.page,
                    page_size: options.itemsPerPage,
                    order_by: options.sortBy[0],
                    order_desc: options.sortDesc[0],
                };
                this.$store.dispatch(
                    "customersAdminMgmt/retrieveItems",
                    params
                );
            } else {
                this.searchItems(options, term);
            }
        },
        toggleBulkActions(payload) {
            if (payload.value == true) {
                let options = payload.items.map((i) => i.id);
                this.$store.commit(
                    "customersAdminMgmt/BULK_OPS_UPDATE",
                    options
                );
            } else {
                this.$store.commit("customersAdminMgmt/BULK_OPS_UPDATE", []);
            }
        },
        // Action operations
        disableItem(payload) {
            this.$store.dispatch("customersAdminMgmt/disableItem", payload);
        },
        enableItem(payload) {
            this.$store.dispatch("customersAdminMgmt/enableItem", payload);
        },
        removeItem(payload) {
            this.$store.dispatch("customersAdminMgmt/removeItem", payload);
        },
        viewItem(record) {
            let payload = { form: "customer", item: record };
            this.$store.commit("customersAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("customersAdminMgmt/SHOW_VIEW_OPS_DIALOG");
        },
        // Dialog operations
        hideCreateDialog() {
            this.$store.commit("customersAdminMgmt/HIDE_CREATE_OPS_DIALOG");
            this.$store.commit("customersAdminMgmt/FLUSH_ITEM");
        },
        hideDisableDialog() {
            this.$store.commit("customersAdminMgmt/HIDE_DISABLE_OPS_DIALOG");
        },
        hideEnableDialog() {
            this.$store.commit("customersAdminMgmt/HIDE_ENABLE_OPS_DIALOG");
        },
        hideRemoveDialog() {
            this.$store.commit("customersAdminMgmt/HIDE_REMOVE_OPS_DIALOG");
        },
        showCreateDialog() {
            this.$store.commit("customersAdminMgmt/SHOW_CREATE_OPS_DIALOG");
        },
        showDisableDialog(item) {
            let payload = { form: "disablePayload", item: item };
            this.$store.commit("customersAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("customersAdminMgmt/SHOW_DISABLE_OPS_DIALOG");
        },
        showEnableDialog(item) {
            let payload = { form: "enablePayload", item: item };
            this.$store.commit("customersAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("customersAdminMgmt/SHOW_ENABLE_OPS_DIALOG");
        },
        showRemoveDialog(item) {
            let payload = { form: "removePayload", item: item };
            this.$store.commit("customersAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("customersAdminMgmt/SHOW_REMOVE_OPS_DIALOG");
        },
        updateLocalForm(event, form) {
            if (event instanceof InputEvent) {
                this[form] = event.target.value;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this[form] = event;
            }
        },
    },
    name: "TableCustomers",
    watch: {
        search(term) {
            this.searchItems(this.options, term);
        },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.retrieveItems(this.options);
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {
        this.$store.commit("tasksAdminMgmt/FLUSH_ITEMS");
    },
};
</script>
